import * as React from "react";
import type { HeadFC } from "gatsby";
import Header_Layout from "../components/layouts/Header_Layout";

const SellPage = () => {
  return <Header_Layout></Header_Layout>;
};

export default SellPage;

export const Head: HeadFC = () => <title>Sell - BitLot.app</title>;
